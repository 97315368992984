/**
 * Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
@use 'sass:map';
@use '@angular/material' as mat;
@import 'variables';

$color-config: mat.m2-get-color-config($alfaTheme);

$primary: map.get($color-config, 'primary');

alfa-vorgang-search-view-item {
  mat-icon {
    color: mat.m2-get-color-from-palette($primary);
  }
}

alfa-vorgang-view-item,
alfa-vorgang-search-view-item {
  ozgcloud-routing-button {
    .mat-mdc-button {
      text-align: left;
      width: 100%;
      border-radius: 1rem;
      font-weight: normal;
      padding: 0 0.875rem !important;
    }

    &.selected:not(:hover) .mat-mdc-button {
      background-color: $activeButton;
      font-weight: 500;
    }
    .mdc-button__label {
      display: flex;
      align-items: center;
      width: 100%;

      .label {
        flex-grow: 1;
      }
    }
  }
}

body.dark {
  alfa-vorgang-view-item,
  alfa-vorgang-search-view-item-container {
    ozgcloud-routing-button {
      &.selected:not(:hover) .mat-mdc-button {
        background-color: $activeButtonDark;
      }
    }
  }
}

alfa-vorgang-view-item {
  ozgcloud-routing-button {
    .dot {
      border-radius: 50%;
      border: 1px solid darken(#e4e4e4, 30%);
      background-color: #e4e4e4;
      margin: 5px;
      margin-right: 11px;
      display: inline-block;
      height: 0.875rem;
      width: 0.875rem;

      &.neu {
        background-color: #c0d1e8;
        border-color: #155ab4;
      }

      &.angenommen,
      &.in_bearbeitung {
        background-color: #f6ebce;
        border-color: #f1c14e;
      }

      &.beschieden,
      &.abgeschlossen,
      &.weitergeleitet {
        background-color: #d9eec5;
        border-color: #7ccb2b;
      }

      &.verworfen {
        background-color: #dedede;
        border-color: #8e8e8f;
      }

      &.zu-loeschen {
        background-color: #fff;
        border-color: #bbb;
      }
    }

    &.selected {
      .dot {
        &.neu {
          background-color: #155ab4;
        }

        &.angenommen {
          background-color: #f1c14e;
        }

        &.in_bearbeitung {
          background-color: #f1c14e;
        }

        &.beschieden {
          background-color: #7ccb2b;
        }

        &.abgeschlossen {
          background-color: #7ccb2b;
        }

        &.verworfen {
          background-color: #8e8e8f;
        }

        &.zu-loeschen {
          background-color: #bbb;
        }
      }
    }
  }
}
